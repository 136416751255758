$blue: #009de9;
$secondary: #00ebbc;
$red: #b51313;
$orange: #ffce2c;
$green: #8ec547;

$grey-dark: #202020;
$grey-medium: #5a5a5a;
$grey-light: #8f8f8f;

/* Shades of Grey */
$grey6: #f8f8f8;
$grey5: #f0f0f0;
$grey4: #d0d0d0;
$grey3: #c0c0c0;
$grey2: #b0b0b0;
$grey1: #a0a0a0;
